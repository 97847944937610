import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { makeTheme } from '../utils/themeCreator';
export const SiteThemeContext = React.createContext({
    theme: 'light',
    bold: 'false',
    handleThemeChange: () => { },
    handleBoldThemeChange: () => { },
});
export const SiteThemeProvider = (props) => {
    const { children } = props;
    const [theme, setTheme] = useState((window.localStorage.getItem('scribe_theme') === 'dark' ? 'dark' : 'light'));
    const [bold, setBold] = useState(window.localStorage.getItem('scribe_theme_bold') || 'false');
    const handleThemeChange = (value) => {
        const newTheme = value || (theme === 'dark' ? 'light' : 'dark');
        window.localStorage.setItem('scribe_theme', newTheme);
        setTheme(newTheme);
    };
    const handleBoldThemeChange = () => {
        window.localStorage.setItem('scribe_theme_bold', bold === 'true' ? 'false' : 'true');
        setBold(bold === 'true' ? 'false' : 'true');
    };
    const themeMaterial = makeTheme(theme, bold);
    return (React.createElement(SiteThemeContext.Provider, { value: {
            theme,
            bold,
            handleThemeChange: handleThemeChange,
            handleBoldThemeChange: handleBoldThemeChange,
        } },
        React.createElement(StyledEngineProvider, { injectFirst: true },
            React.createElement(ThemeProvider, { theme: themeMaterial },
                React.createElement(CssBaseline, null),
                children))));
};
