import { createSelector } from 'reselect';
const selectUserProfile = (state) => state.userProfile;
export const selectSubscription = createSelector([selectUserProfile], (userProfile) => userProfile.subscription);
export const selectFeatures = createSelector([selectUserProfile], (userProfile) => userProfile.features);
export const selectFeaturesScribe = createSelector([selectFeatures], (features) => Boolean(features === null || features === void 0 ? void 0 : features.scribe));
export const selectFeature = (feature) => createSelector([selectFeatures], (features) => features === null || features === void 0 ? void 0 : features[feature]);
export const selectUpdateFeaturesLoading = createSelector([selectUserProfile], (userProfile) => userProfile.updateFeaturesLoading);
export const selectCountry = createSelector([selectUserProfile], (userProfile) => { var _a; return (_a = userProfile.avaUser) === null || _a === void 0 ? void 0 : _a.country; });
export const selectCity = createSelector([selectUserProfile], (userProfile) => { var _a; return (_a = userProfile.avaUser) === null || _a === void 0 ? void 0 : _a.city; });
export const selectFeedbackMetrics = createSelector([selectUserProfile], (userProfile) => { var _a; return (_a = userProfile.avaUser) === null || _a === void 0 ? void 0 : _a.convoMetrics; });
export const selectOrgProperties = createSelector([selectUserProfile], (userProfile) => { var _a; return (_a = userProfile.profile) === null || _a === void 0 ? void 0 : _a.orgProperties; });
export const selectRole = createSelector([selectUserProfile], (userProfile) => { var _a; return (_a = userProfile.profile) === null || _a === void 0 ? void 0 : _a.role; });
export const selectHearingProfile = createSelector([selectUserProfile], (userProfile) => { var _a; return (_a = userProfile.profile) === null || _a === void 0 ? void 0 : _a.hearingProfile; });
export const selectUpdateHearingProfileLoading = createSelector([selectUserProfile], (userProfile) => userProfile.updateHearingProfileLoading);
export const selectParse = createSelector([selectUserProfile], (userProfile) => userProfile.parse);
export const selectAvaId = createSelector([selectParse], (parse) => parse === null || parse === void 0 ? void 0 : parse.avaId);
export const selectIsUserTemporary = createSelector([selectParse], (parse) => (parse === null || parse === void 0 ? void 0 : parse.accountType) === 'Temporary');
export const selectOrganization = createSelector([selectParse], (parse) => parse === null || parse === void 0 ? void 0 : parse.organization);
export const selectUserName = createSelector([selectParse], (parse) => parse === null || parse === void 0 ? void 0 : parse.userName);
export const selectAvaName = createSelector([selectParse], (parse) => parse === null || parse === void 0 ? void 0 : parse.avaName);
export const selectAccountType = createSelector([selectParse], (parse) => parse === null || parse === void 0 ? void 0 : parse.accountType);
export const selectUserPhotoURL = createSelector([selectParse], (parse) => { var _a; return (_a = parse === null || parse === void 0 ? void 0 : parse.userPhoto) === null || _a === void 0 ? void 0 : _a.url; });
export const selectDisplayName = createSelector([selectParse], (parse) => (parse === null || parse === void 0 ? void 0 : parse.userName) || (parse === null || parse === void 0 ? void 0 : parse.avaName));
export const selectIsAdmin = createSelector([selectUserProfile], (userProfile) => { var _a, _b, _c; return ((_b = (_a = userProfile.profile) === null || _a === void 0 ? void 0 : _a.orgProperties) === null || _b === void 0 ? void 0 : _b.role) === 'admin' || ((_c = userProfile.profile) === null || _c === void 0 ? void 0 : _c.role) === 'admin'; });
export const selectDisplayId = createSelector([selectParse], (parse) => {
    if (!parse)
        return 'Logged Out';
    if (parse.emails) {
        return Array.from(Object.values(parse.emails))[0];
    }
    if (parse.phoneNumber) {
        return parse.phoneNumber;
    }
    if (parse.accountType === 'Temporary') {
        return parse.accountType;
    }
    return parse.firebaseAuthUID || '';
});
export const selectUpdateUserNameLoading = createSelector([selectUserProfile], (userProfile) => userProfile.updateUserNameLoading);
export const selectUpdateUserNameSuccess = createSelector([selectUserProfile], (userProfile) => userProfile.updateUserNameSuccess);
export const getPaidASRCreditTime = createSelector([selectUserProfile], (userProfile) => {
    return userProfile.paidASRCreditTime;
});
export const getScribeRemainingTime = createSelector([selectUserProfile], (userProfile) => {
    return userProfile.scribeRemainingTime;
});
export const selectUserProfileFetchFinished = createSelector([selectUserProfile], (userProfile) => userProfile.userProfileFetchFinished);
export const selectUserProfileFetchInitiated = createSelector([selectUserProfile], (userProfile) => userProfile.userProfileFetchInitiated);
export const selectIsProfileComplete = createSelector([selectUserProfile, selectHearingProfile], (userProfile, hearingProfile) => {
    var _a, _b;
    const { avaUser } = userProfile;
    if (!avaUser)
        return false;
    const { parse: { organizationId, userName }, } = avaUser;
    let bestHearingProfile = hearingProfile;
    if ((hearingProfile === -1 || hearingProfile === undefined) && ((_a = avaUser.profile) === null || _a === void 0 ? void 0 : _a.hearingProfile) !== undefined) {
        bestHearingProfile = (_b = avaUser.profile) === null || _b === void 0 ? void 0 : _b.hearingProfile;
    }
    return (bestHearingProfile !== -1 ||
        // The user has had their first conversation
        avaUser.convoMetrics.total_conversations > 0 ||
        // or we already have all the necessary info
        (userName && organizationId));
});
export const selectIsWebGuest = createSelector([selectUserName], (userName) => userName === 'web guest');
