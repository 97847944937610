import { createSelector } from 'reselect';
import { binarySearchArray, compareIntegerStrings } from '../utils/binarySearch';
import { isDefaultRoomId } from '../utils/ws-v1';
import { selectAvaId, selectFeatures } from './userProfile';
const selectScribeConversation = (state) => state.scribeConversation;
// DO NOT USE. Gets updated 10 times a second sometimes. Use sub-selectors.
export const selectStatus = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.status);
export const selectHostAvaId = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.host) === null || _a === void 0 ? void 0 : _a.avaId; });
export const selectHasHostEnableSpeakerId = createSelector([selectStatus], (status) => {
    var _a;
    const hostFlags = (_a = status === null || status === void 0 ? void 0 : status.host) === null || _a === void 0 ? void 0 : _a.flags;
    return (hostFlags === null || hostFlags === void 0 ? void 0 : hostFlags['mono-segmentation']) && (hostFlags === null || hostFlags === void 0 ? void 0 : hostFlags['nats']);
});
const selectStatusPermissions = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.permissions);
export const selectPermissionsTranslation = createSelector([selectStatusPermissions], (permissions) => { var _a; return Boolean((_a = permissions === null || permissions === void 0 ? void 0 : permissions.translation) === null || _a === void 0 ? void 0 : _a.value); });
export const selectStatusScribe = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.scribe);
// Since it's an array of objects we shouldn't rely on it being memoized properly,
// use when necessary but it can lead to performance issues
export const selectScribes = createSelector([selectStatusScribe], (status) => status === null || status === void 0 ? void 0 : status.scribes);
export const selectStatusOfScribes = createSelector([selectStatusScribe], (scribeStatus) => scribeStatus === null || scribeStatus === void 0 ? void 0 : scribeStatus.status);
export const selectScribesHashes = createSelector([selectScribes], (scribes) => scribes === null || scribes === void 0 ? void 0 : scribes.map((scribe) => scribe.hash));
export const selectHasScribe = createSelector([selectStatusOfScribes], (scribeStatus) => scribeStatus === 'ongoing');
export const selectPendingScribe = createSelector([selectStatusOfScribes], (scribeStatus) => scribeStatus === 'pending');
export const selectScribeLeftOrLeaving = createSelector([selectStatusOfScribes], (scribeStatus) => scribeStatus === 'ended' || scribeStatus === 'leaving');
export const selectScribeRequestor = createSelector([selectStatusScribe], (statusScribe) => statusScribe === null || statusScribe === void 0 ? void 0 : statusScribe.requestor);
//TODO type this better with types from schema
export const selectStatusAudioStreams = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.audioStreams);
export const selectStatusAsr = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.asr);
export const selectStatusRoomId = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.id);
export const selectStatusHostAvaId = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.host) === null || _a === void 0 ? void 0 : _a.avaId; });
export const selectStatusHostAvaName = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.host) === null || _a === void 0 ? void 0 : _a.avaName; });
export const selectIsDefaultRoomId = createSelector([selectStatusRoomId], (roomId) => roomId && isDefaultRoomId(roomId));
export const selectStatusConversationMode = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.conversationMode);
export const selectStatusTitle = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.title);
export const selectStatusLinksRo = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.links) === null || _a === void 0 ? void 0 : _a.ro; });
export const selectStatusPast = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.past);
export const selectStatusReason = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.reason);
export const selectStatusStateValue = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.state) === null || _a === void 0 ? void 0 : _a.value; });
export const selectStatusRestrictionsConvoWarningCountdownTimerMs = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.restrictions) === null || _a === void 0 ? void 0 : _a.convoWarningCountdownTimerMs; });
export const selectTwilioCalls = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.twilioCalls);
export const selectStatusBoost = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.boost);
// This is similar to status.participants, but:
// * It is a map, not an array
// * It can contain virtual participants (e.g. 'recall-ai')
// * It is enhanced with solo-dia information in the reducer
export const selectSpeakersMap = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.speakers);
/**
 * Basically, speakers and transcriptOwners refer the same map, this map is Map<AvaId, api.Speaker> (transcriptOwnerMap)
 * the map is being used internally in the backend codebase, and we don’t expose it directly in room-status.
 * speakers is an array and the value is the values of the map above.
 * transcriptOwners is a list of string and the value is the keys of the map above.
 */
export const selectStatusTranscriptOwners = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.transcriptOwners);
// solo-dia speakers follow this pattern 'speaker_1', 'speaker_2', etc.
export const selectSoloDiaSpeakers = createSelector([selectSpeakersMap], (speakers) => {
    if (!speakers)
        return [];
    return Object.values(speakers);
});
// This is similar to speakers, but:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is exactly as returned by the backend (duplicate participants?)
export const selectParticipants = createSelector([selectScribeConversation], (scribeConversation) => { var _a; return ((_a = scribeConversation.status) === null || _a === void 0 ? void 0 : _a.participants) || []; });
export const selectParticipantsLength = createSelector([selectParticipants], (participants) => participants.length);
export const selectTranscriptOwnersLength = createSelector([selectStatus], (status) => { var _a; return ((_a = status === null || status === void 0 ? void 0 : status.transcriptOwners) === null || _a === void 0 ? void 0 : _a.length) || 0; });
export const selectParticipantsPresent = createSelector([selectParticipants], (participants) => !!participants);
//TODO Remove filtering for scribe once backend removes backwards compatability
// This is the best of both worlds for selectParticipants and selectSpeakers:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is enhanced with solo-dia information in the reducer
// * It does not contain duplicates
export const selectUniqueParticipants = createSelector([selectParticipants, selectSpeakersMap], (participants, speakers) => {
    const unique = [...new Set(participants.map((p) => p.avaId))].map((avaId) => speakers[avaId]);
    const filtered = unique.filter((participants) => participants !== undefined);
    return filtered;
});
export const selectUnionSpeakersAndParticipants = createSelector([selectParticipants, selectSpeakersMap], (participants, speakersMap) => {
    const uniqueAvaIds = new Set(participants.map((p) => p.avaId));
    Object.values(speakersMap).forEach((speaker) => {
        uniqueAvaIds.add(speaker.avaId);
    });
    const unionParticipantsAndSpeakers = Array.from(uniqueAvaIds).map((avaId) => {
        const participant = participants.find((p) => p.avaId === avaId);
        if (participant)
            return participant;
        return Array.from(Object.values(speakersMap)).find((s) => s.avaId === avaId);
    });
    return unionParticipantsAndSpeakers;
});
export const selectTranscripts = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcripts);
export const selectTranscriptsCurrent = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcriptsCurrent);
export const selectTranscriptsFinal = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcriptsFinal);
export const selectTotalTranscriptCount = createSelector([selectTranscriptsCurrent, selectTranscriptsFinal], (transcriptsCurrent, transcriptsFinal) => transcriptsCurrent.length + transcriptsFinal.length);
export const selectHaveTranscripts = createSelector([selectTotalTranscriptCount], (totalTranscriptCount) => totalTranscriptCount > 0);
export const selectLang = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.lang);
export const selectSpeechLang = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.speechLang);
export const selectAllTranscriptIds = createSelector([selectTranscriptsCurrent, selectTranscriptsFinal], (current, final) => final.concat(current));
export const selectAllTranscripts = createSelector([selectTranscripts, selectAllTranscriptIds], (transcripts, allTranscriptsIds) => allTranscriptsIds.map((transcriptId) => transcripts[transcriptId]));
export const selectTranscript = createSelector([selectTranscripts, (state, transcriptId) => transcriptId], (transcripts, transcriptId) => transcripts[transcriptId]);
export const selectLastTranscriptId = createSelector([selectAllTranscriptIds], (transcripts) => transcripts[transcripts.length - 1]);
// This selector is O(log N) in the worst case, doing a binary search over all transcripts
export const selectNeighborTranscriptAuthors = createSelector([
    selectTranscripts,
    selectTranscriptsCurrent,
    selectTranscriptsFinal,
    (state, transcriptId) => transcriptId,
], (transcripts, transcriptsCurrent, transcriptsFinal, transcriptId) => {
    var _a, _b;
    const allTranscriptIds = [...transcriptsFinal, ...transcriptsCurrent];
    const transcriptIndex = binarySearchArray(allTranscriptIds, transcriptId, compareIntegerStrings);
    if (transcriptIndex === -1) {
        return { previousTranscriptAuthor: undefined, nextTranscriptAuthor: undefined };
    }
    const previousTranscriptId = allTranscriptIds[transcriptIndex - 1];
    const nextTranscriptId = allTranscriptIds[transcriptIndex + 1];
    return {
        previousTranscriptAuthor: previousTranscriptId && ((_a = transcripts[previousTranscriptId]) === null || _a === void 0 ? void 0 : _a.author),
        nextTranscriptAuthor: nextTranscriptId && ((_b = transcripts[nextTranscriptId]) === null || _b === void 0 ? void 0 : _b.author),
    };
});
export const selectHighLightedById = createSelector([selectTranscripts, (state, transcriptId) => transcriptId], (transcripts, transcriptId) => { var _a; return Boolean((_a = transcripts === null || transcripts === void 0 ? void 0 : transcripts[transcriptId]) === null || _a === void 0 ? void 0 : _a.highlighted); });
export const selectAsrFinalById = createSelector([selectTranscripts, (state, transcriptId) => transcriptId], (transcripts, transcriptId) => { var _a; return Boolean((_a = transcripts === null || transcripts === void 0 ? void 0 : transcripts[transcriptId]) === null || _a === void 0 ? void 0 : _a['asr.final']); });
export const selectTranscriptAuthor = createSelector([selectTranscript], (transcript) => transcript.author);
export const selectFurthestObservedCursors = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.furthestObservedCursors);
export const selectIsHost = createSelector([selectStatus, selectAvaId], (status, avaId) => {
    // we check status.host.avaName for DevExp purposes
    // since when changing to other servers (eg PENTEST)
    // the first status.host.avaId is a default host value
    // that's unrelated to the actual host or any participants
    if (status && status.host && status.host.avaId && status.host.avaName && avaId) {
        return avaId === status.host.avaId;
    }
    else
        return false;
});
export const selectSoloDiaSpeakersWhoSpoke = createSelector([selectScribeConversation, selectSoloDiaSpeakers], (scribeConversation, soloDiaSpeakers) => soloDiaSpeakers.filter((speaker) => scribeConversation.speakersWhoSpoke.includes(speaker.avaId)));
export const selectSoloDiaSpeakerMeExists = createSelector([selectSoloDiaSpeakers], (soloDiaSpeakers) => soloDiaSpeakers.find((speaker) => speaker.myself));
export const selectSpeaker = createSelector([selectSpeakersMap, selectTranscriptAuthor, selectAvaId, selectFeatures], (speakers, author, avaId, features) => {
    if (!avaId)
        return undefined;
    const currentUser = speakers[avaId];
    // Who the transcript is related
    const currentSpeaker = speakers[author];
    let speaker = currentUser;
    // When a scribe/user splits a transcript the transcript.author is briefly 'Callee'
    // before it is corrected. We need to handle this brief moment so it doesn't crash the app.
    if (currentSpeaker && (!currentSpeaker.isSoloDiaSpeakerCurrentUser || features['mono-segmentation'])) {
        // If is Solo-Dia and is marked as myself = true then use currentUser speaker object.
        speaker = currentSpeaker;
    }
    return speaker;
});
